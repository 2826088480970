<template lang="pug">
.brand-campaign-share.d-flex.align-items-center.justify-content-center
  .brand-campaign-share-content.col-auto
    h1.brand-campaign-share-title {{ $t('campaignShare.prepare.title') }}
    p.brand-campaign-share-subtitle {{ $t('campaignShare.prepare.subtitle') }}
    loading-logo.mt-5
</template>

<script>
  import CAMPAIGN_SHARE_BY_TOKEN from '@/graphql/CampaignShareByToken.gql';
  import { CAMPAIGN_SHARE_LS_KEY } from '@/config/constants';
  import localStorageMixin from '@/mixins/localStorage';

  export default {
    mixins: [localStorageMixin],
    data: () => ({
      campaignShare: null,
    }),
    computed: {
      token() {
        return this.$route?.params?.token;
      },
    },
    apollo: {
      campaignShare: {
        query: CAMPAIGN_SHARE_BY_TOKEN,
        variables() {
          return { token: this.token };
        },
        skip() {
          return !this.token;
        },
        result() {
          this.processResult();
        },
      },
    },
    methods: {
      processResult() {
        if (this.campaignShare) {
          this.hasObject();
        } else {
          this.missingObject();
        }
      },
      hasObject() {
        this.lsStore(CAMPAIGN_SHARE_LS_KEY, this.token);
        this.$notify({
          type: 'success',
          text: this.$t('campaignShare.prepare.notifications.found'),
        });
        this.$router.replace({ name: 'login' });
      },
      missingObject() {
        this.$notify({
          type: 'error',
          text: this.$t('campaignShare.prepare.notifications.invalidToken'),
        });
        this.$router.replace({ name: 'templates' });
      },
    },
  };
</script>

<style lang="sass">
  .brand-campaign-share
    min-height: 100vh
    text-align: center
    &-content
      margin: 1.5rem 0
</style>
